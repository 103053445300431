import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-social'
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-trumbowyg/dist/trumbowyg.min.css'
import './App.css';
import { useSelector } from "react-redux";

import HomePage from './pages/HomePage.js'
import AdminPage from './pages/AdminPage.js'

export default function App(props) {
	const loggedInStatus = useSelector(state => state.reducers.loggedInStatus)
	const user = useSelector(state => state.reducers.user)

	return (
		<div className="app">
			<BrowserRouter>
				<Switch>
					<Route
						exact
						path={"/admin"}
						render={props => (
							loggedInStatus && user.master_admin ?
				            <AdminPage {...props} /> 
				            :
				            <Redirect push to="/"/>
						)}

					/>
					<Route
						exact
						path={"/:obj?/:id?"}
						render={props => (
							<HomePage
								{...props}
							/>
						)}
					/>
					{<Redirect push to="/"/> }
				</Switch>
			</BrowserRouter>
		</div>
	);
}