import React, { useState, useRef, useEffect } from 'react'
import parse from 'html-react-parser';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spinner, InputGroup, FormControl, Col, Row, Form, Modal } from 'react-bootstrap';
import $ from "jquery";
import Trumbowyg from 'react-trumbowyg';
import ImageUploader from 'react-images-upload';
import ImageGallery from 'react-image-gallery';

import { setCart, setCartData, toggleLogin, toggleProduct, setProduct, toggleCart, setPageControls } from '../actions/actions.js'

const status = [ null, 'New', 'Active', null, 'Phase-out', 'Allocated', null, null, null, 'Special Order']

export default function Product(props) {
	const dispatch = useDispatch()
	const cart = useSelector(state => state.reducers.cart)
	const product = useSelector(state => state.reducers.product)
	const products = useSelector(state => state.reducers.products)
	const cartData = useSelector(state => state.reducers.cartData)
	const loggedInStatus = useSelector(state => state.reducers.loggedInStatus)
	const [ state, setState ] = useState({})
	const loading = useSelector(state => state.reducers.loading)
	const productModal = useSelector(state => state.reducers.productModal)
	const user = useSelector(state => state.reducers.user)
	const pageControls = useSelector(state => state.reducers.pageControls)
	const [ edit, setEdit ] = useState(false)
	const [ test, setTest ] = useState('test')
	const [ editorText, setEditorText ] = useState('')
	const [ mainImage, setMainImage ] = useState(0)
	const [ uploaded, setUploaded ] = useState([])
	const [ imagesUrls, setImageUrls ] = useState([])
	const [ editedProduct, setEditedProduct ] = useState({})

	const data = useRef('test')
	const gallery = useRef(null)

	useEffect(() => {
		var galleryObj = []
		if (product.images !== undefined) {
			if (product.images.length !== imagesUrls.length || product.images.length === 0) {
				product.images.forEach(image => {
					galleryObj.push({
						thumbnail: image,
						original: image
					})
				})
				if (product.images.length === 0)
					galleryObj.push({thumbnail: 'placeholder.jpg', original: 'placeholder.jpg'})
				setImageUrls(galleryObj)
			}
		}
	}, [product])

	useEffect(() => {
		if (edit)
			gallery.current.slideToIndex(0)
	}, [edit])

	useEffect(() => {
		if (editedProduct)
			dispatch(setProduct({...product, ...editedProduct}))
	}, [editedProduct])

	useEffect(() => {
		if (product.images) {
			var newOrder = [...product.images]
			var temp = newOrder[0]
			newOrder[0] = newOrder[mainImage]
			newOrder[mainImage] = temp
			setEditedProduct({images: newOrder})
		}
	}, [mainImage])

	function updateCart(product) {
		axios
			.put("/user",
				{user: {...user, cart: {...cart, [product]: 1}}}
			)
			.then(response => {
				dispatch(setCart({...cart, [product]: 1}))
			})
			.catch(error => {
				console.log(error)
			})
	}

	function handleSubmit(event) {
    	event.preventDefault()
    	console.log(product)
    	if (loggedInStatus) {
	    	var tempCart = {...cart}
			tempCart[product.data.PART_NUM] = (state[product.data.PART_NUM] !== undefined ? parseInt(state[product.data.PART_NUM]) : 1)
	    	dispatch(toggleCart(true))
    	
    		axios
				.put("/user",
					{user: {...user, cart: tempCart}}
				)
				.then(response => {
					dispatch(setCart({...tempCart}))
				})
				.catch(error => {
					console.log("login error: ", error)
				})
    	}
    	else
    		dispatch(toggleLogin(true))
    }

    function handleChange(event) {
    	var key = event.target.name
    	var val = parseInt(event.target.value)
    	setState(prevState => ({...prevState, [key]: val}))
    }

    function onDrop(pictures) {
    	if (uploaded.length < pictures.length) {
    		var form = new FormData();
		    form.append('id', product.data.PART_NUM)
		    pictures.forEach(file => {
		    	if (uploaded.indexOf(file.name) === -1)
		        	form.append('product-images', file);
		    });
	    	axios.post('/upload-images', form)
	    	.then(result => {
	    		var uploadedFiles = []
	    		var newImages = []
	    		var newUrls = []
	    		result.data.forEach(file => {
	    			uploadedFiles.push(file.originalname)
	    			newUrls.push(file.filename)
	    			newImages.push({ 
	    				thumbnail: file.filename, 
	    				original: file.filename})
	    		})
	    		setUploaded([...uploaded, ...uploadedFiles])
				setImageUrls([...imagesUrls, ...newImages])
				dispatch(setProduct({...product, images: [...product.images, ...newUrls]}))
	    	})
	    	.catch(err => {
	    		console.log(err)
	    	})
    	}
    }

    function updateProduct(product) {
    	axios.put('/product', {product: product})
    	.then(result => {
			console.log(result.data)
			dispatch(toggleProduct(false))
			setImageUrls([])
			setEdit(false)
    	})
    	.catch(err => {
    		console.log(err)
    	})
    }

    function getPrice(product) {
    	
    }


    if (loading)
		return (
			<Card>
				<Card.Body className='spinner-cat'>
					<Spinner animation="border" variant='info' size='lg'/>
				</Card.Body>
			</Card>
		)

    let list = products.map((currentObj, i) => {
    	var urls = []
		currentObj.images.forEach(file => {
			urls.push({thumbnail: file, original: file})
		})
		if (urls.length === 0)
			urls.push({thumbnail: 'placeholder.jpg', original: 'placeholder.jpg'})
  		return (
  			<Card body key={currentObj.data.PART_NUM}>
  				<Row>
  					<Col className='float-left mb-2'>
						<div onClick={() => {
							dispatch(setProduct(currentObj)); 
							dispatch(toggleProduct(true))}} 
							href='#' className='product pointer'>{currentObj.title}
						</div>
  					</Col>
  				</Row>
  				<Row>
  					<Col lg={4} md={4} sm={4} xs={12} className='mb-2'>
		  				<ImageGallery
							items={urls} 
							showPlayButton={false} 
							showNav={false}
							showFullscreenButton={false}
							renderItem = { item => 
								<img className="image-gallery-image product-image" src={'/images/products/' + item.original}></img>
							}
							showThumbnails={false}
							onClick={() => {
								dispatch(setProduct(currentObj)); 
								dispatch(toggleProduct(true))}
							} 
						/>
					</Col>
  					<Col lg={8} md={8} sm={8} xs={12}>
  						<Row>
		  					<Col lg={6} md={6} sm={6} xs={5}>
		  						<div className='description'>
		  							<br></br>
									{currentObj.data.PART_NUM ? "TD#: " + currentObj.data.PART_NUM : ''} <br></br>
									{currentObj.data.MANU_PART ? "MFC#: " + currentObj.data.MANU_PART : ''} <br></br>
									{currentObj.data.UPC ? "UPC#: " + currentObj.data.UPC : ''} <br></br> <br></br>
									{currentObj.data.STATUS ? "Status: " + status[currentObj.data.STATUS] : ''}
								</div>
		  					</Col>
		  					<Col lg={6} md={6} sm={6} xs={7}>
		  						<div className='justify-content-end'>
		  							<div className='product-retail justify-content-end'>
		  								{"MSRP: $" + parseFloat(currentObj.data.RETAIL).toFixed(2)}
		  							</div>
		  							<Form onSubmit={event => handleSubmit(event)}>
										<InputGroup className="mb-3 mt-3 justify-content-end">
											<FormControl
												placeholder="1"
												className='search-query qty-bar'
												name={currentObj.data.PART_NUM}
												onChange={event => handleChange(event)}
											/>
											<InputGroup.Append>
											<Button variant="info" type='submit' onClick={() => dispatch(setProduct(currentObj))}>
												<div className="fas fa-plus product-button plus"></div>
												<div className="fas fa-shopping-cart product-button"></div>
											</Button>
											</InputGroup.Append>
										</InputGroup>
									</Form>
									{loggedInStatus ? 
										<div className='product-cost'>
		  									You Pay: ${parseFloat(currentObj.data.COST).toFixed(2)}
		  								</div> : 	
		  								<div className='product-cost pointer' onClick={event => {event.preventDefault(); dispatch(toggleLogin(true))}}>
		  									Login for your price
		  								</div>}
								</div>  
		  					</Col>
		  				</Row>
	  				</Col>
  				</Row>				
			</Card>
  		);
	});

    return (
		<div>
			{list}
			{
				productModal ?
					<Modal
						{...props}
						show={true}
						size='xl'
				        onHide={() => {
				        	dispatch(toggleProduct(false))
				        	setImageUrls([])
				        	setUploaded([])
				        }}
				        scrollable='true'
					>
						<Modal.Body>
							{
								user.master_admin && edit ?
									<div>
										<Form.Control 
											className="searchbar"
											name='title'
											value={product.title || ''}
											onChange={(e) => {
												setEditedProduct({title: e.target.value})
											}}
										/>
										<Row className='mb-4'>
											<Col lg={9} md={12} sm={12} xs={12} className='mb-3'>
												<ImageGallery
													items={imagesUrls} 
													ref={gallery}
													showPlayButton={false} 
													showNav={false}
													showFullscreenButton={false}
													renderItem = { item => 
														<img class="image-gallery-image" src={'/images/products/' + item.original}></img>
													}
													renderThumbInner={ item => 
														<div className='thumbnail-container'>
															<img class="image-gallery-thumbnail-image thumbnail-image" 
																src={'/images/products/' + item.thumbnail} onClick={() => {
																setMainImage(imagesUrls.findIndex(props => props.thumbnail === item.thumbnail))
															}}></img>
															<Button variant='danger' className='p-0 m-0 button-overlay' onClick={() => {
																var updateImages = [...product.images]
																updateImages.splice(
																	updateImages.indexOf(item.thumbnail),1)
																setEditedProduct({images: updateImages})
															}}>
																<div className='fas fa-times pl-2 pr-2 product-button plus'></div>
															</Button>
														</div>
													}
												/>
											</Col>
											<Col lg={3} md={12} sm={12} xs={12}>
												<Row>
													<Col lg={12} md={6} sm={12} xs={12}>
														{loggedInStatus ? 
															<div className='product-cost-modal'>
							  									${parseFloat(product.data.COST).toFixed(2)}
							  								</div> : 	
							  								<div className='product-cost-modal pointer' onClick={event => {event.preventDefault(); dispatch(toggleLogin(true))}}>
							  									Login for your price
							  								</div>
							  							}
							  							<div className='product-retail-modal'>
							  								{"MSRP: $" + parseFloat(product.data.RETAIL).toFixed(2) + ' (Save ' + 
							  								((parseFloat(product.data.RETAIL)-parseFloat(product.data.COST))/parseFloat(product.data.RETAIL)*100).toFixed(0) + '%!)'}
							  							</div>
							  							<Button className='mt-2 mb-2 searchbar' variant='info'>Add To Cart</Button>
							  						</Col>
							  						<Col lg={12} md={6} sm={12} xs={12}>
							  							<div className='plus'>
							  								<span>Status: {status[product.data.STATUS]}</span><br></br>
															<span>TD#: {product.data.PART_NUM}</span><br></br>
															<span>MFC#: {product.data.MANU_PART}</span><br></br>
															{product.data.UPC ? <span><span>UPC: {product.data.UPC}</span><br></br></span> : ''}
															<span>Stock: {product.data.QTY}</span><br></br>
														</div>
													</Col>
												</Row>
											</Col>
										</Row>
										<ImageUploader
											buttonText='Choose images'
											onChange={(e) => onDrop(e)}
											imgExtension={['.jpg', '.gif', '.png', '.gif']}
											maxFileSize={5242880}
											withPreview={false}
										/>
										<Trumbowyg id='react-trumbowyg'
											buttons={
											[
												['viewHTML'],
												['formatting'],
												'btnGrp-semantic',
												['link'],
												['insertImage'],
												'btnGrp-justify',
												'btnGrp-lists'
											]}
											data={editorText}
											placeholder='Type or Copy and Paste the product description.'
											onChange={(e) => {
												setEditedProduct({description: e.currentTarget.innerHTML})
											}}
											onPaste={(e) => {
												setEditedProduct({description: e.currentTarget.innerHTML})
											}}
											ref={data}
										/>
									</div>
								:
									<div>
										<div className='mb-3'>
											{product.title}
										</div>
										<Row className='mb-4'>
											<Col lg={9} md={12} sm={12} xs={12} className='mb-3'>
												<ImageGallery
													ref={gallery}
													items={imagesUrls} 
													showPlayButton={false} 
													showNav={false}
													showFullscreenButton={false}
													renderItem = { item => 
														<img className="image-gallery-image" src={'/images/products/' + item.original}></img>
													}
													renderThumbInner={ item => 
														<img className="image-gallery-thumbnail-image thumbnail-image" src={'/images/products/' + item.thumbnail}></img>
													}
												/>
											</Col>
											<Col lg={3} md={12} sm={12} xs={12}>
												<Row>
													<Col lg={12} md={6} sm={12} xs={12}>
														{loggedInStatus ? 
															<div className='product-cost-modal'>
							  									${parseFloat(product.data.COST).toFixed(2)}
							  								</div> : 	
							  								<div className='product-cost-modal pointer' onClick={event => {
							  									dispatch(toggleProduct(false))
							  									dispatch(toggleLogin(true))}}>
							  									Login for your price
							  								</div>
							  							}
							  							<div className='product-retail-modal'>
							  								{"MSRP: $" + parseFloat(product.data.RETAIL).toFixed(2)}
							  								{ loggedInStatus ? ' (Save ' + 
							  									((parseFloat(product.data.RETAIL)-parseFloat(product.data.COST))/parseFloat(product.data.RETAIL)*100).toFixed(0) + '%!)'
							  								:
							  									''
							  								}
							  							</div>
							  							<Button className='mt-2 mb-2 searchbar' variant='info' onClick={() => {
							  								if (loggedInStatus) {
							  									updateCart(product.id)
							  									dispatch(toggleProduct(false))
							  									dispatch(toggleCart(true))
							  								}
							  								else{
							  									dispatch(toggleProduct(false))
							  									dispatch(toggleLogin(true))
							  								}
							  							}}>Add To Cart</Button>
							  						</Col>
							  						<Col lg={12} md={6} sm={12} xs={12}>
							  							<div className='plus'>
							  								<span>Status: {status[product.data.STATUS]}</span><br></br>
															<span>TD#: {product.data.PART_NUM}</span><br></br>
															<span>MFC#: {product.data.MANU_PART}</span><br></br>
															{product.data.UPC ? <span><span>UPC: {product.data.UPC}</span><br></br></span> : ''}
															<span>Stock: {product.data.QTY}</span><br></br>
														</div>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row className=''>
											<Col lg={12} md={12} sm={12} xs={12} className='overflow-hidden'>
												{parse(product.description)}
											</Col>
										</Row>
									</div>
							}
						</Modal.Body>
						<Modal.Footer>
							{
								user.master_admin ?
									<Button className='m-1' variant='info' onClick={(e) => {
										if (edit) {
											updateProduct(product)
											setUploaded([])
											dispatch(setPageControls({...pageControls}))
										}
										else {
											setEditorText(product.description)
											setEdit(true)
										}

									}}>
										{
											edit ?
												<div className="fas fa-save product-button plus"></div>
											:
												<div className="fas fa-pen product-button plus"></div>
										}
									</Button>
								:
									''
							}
							<Button variant="secondary" className='pl-3 pr-3' onClick={() => {
								dispatch(toggleProduct(false))
					        	setImageUrls([])
					        	setUploaded([])
							}}>Cancel</Button>
							<Button variant="info" className='pl-3 pr-3' onClick={() => {
								if (loggedInStatus) {
  									updateCart(product.id)
  									dispatch(toggleProduct(false))
  									dispatch(toggleCart(true))
  								}
  								else{
  									dispatch(toggleProduct(false))
  									dispatch(toggleLogin(true))
  								}
							}}>
								<div className="fas fa-plus product-button plus"></div>
								<div className="fas fa-shopping-cart product-button"></div>
							</Button>
						</Modal.Footer>
					</Modal>
				:
				''
			}
		</div>
	)
}