import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { PayPalButton } from "react-paypal-button-v2";
import axios from 'axios'
import { config } from './../config.js'
import { useDispatch, useSelector } from "react-redux";
import { toggleProfile } from './../actions/actions'

export default function Profile(props) {
	const dispatch = useDispatch()
	const profileModal = useSelector(state => state.reducers.profileModal)
	return (
		<Modal
			{...props}
			show={profileModal}
	        onHide={() => dispatch(toggleProfile(false))}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PayPalButton
			        amount="0.01"
			        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
			        onSuccess={(details, data) => {
			          alert("Transaction completed by " + details.payer.name.given_name);
			 
			          // OPTIONAL: Call your server to save the transaction
			          axios
						.post('/order',
							{
								id: data.orderID,
								order: {
									po: 'CLIENT-123',
									receivingContact: "Ben Wegert",
									recevingAddresss: "7688 211A Street",
									city: "Langley",
									postalCode: "V2Y 0K7",
									name: "Ben Wegert",
									phone: "7782883978",
									email: "ben.wegert@c9s.ca"
								},
								products: [
									{
										idType: "VP",
										id: "678",
										qty: 1
									}
								]

							}
						)
						.then(response => {

						})
						.catch(error => {
							console.log("payment error: ", error)
						})
			        }}
			        options={{
			          clientId: config.clientId
			        }}
			    />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" className='pl-3 pr-3' onClick={() => dispatch(toggleProfile(false))}>Cancel</Button>
				<Button variant="info" className='pl-3 pr-3'>
					<div className="fas fa-plus product-button plus"></div>
					<div className="fas fa-shopping-cart product-button"></div>
				</Button>
			</Modal.Footer>
		</Modal>
	);
}