import React, { useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import qs from 'query-string'
import Slider from "react-slick";

import Navigation from './../components/Navigation'
import Footer from './../components/Footer'
import Sort from './../components/Sort'
import Filter from './../components/Filter'
import Product from './../components/Product'

import { setPageControls, setSplash, toggleProduct } from '../actions/actions.js'


export default function HomePage(props) {
	const dispatch = useDispatch()
	const splash = useSelector(state => state.reducers.splash)
	const loggedInStatus = useSelector(state => state.reducers.loggedInStatus)
	const pageControls = useSelector(state => state.reducers.pageControls)
	const products = useSelector(state => state.reducers.products)


	useEffect(() => {
		if (props.match.params.obj) {
			var obj = qs.parse(props.match.params.obj, {arrayFormat: 'bracket'})
			if (obj.product) 
				obj.keywords = obj.product
			dispatch(setPageControls({...pageControls, ...obj}))
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (pageControls.keywords) {
			props.history.push(qs.stringify(pageControls, {arrayFormat: 'bracket'}))
			dispatch(setSplash(false))
		}
	}, [pageControls]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Navigation history={props.history} match={props.match}/>
		{splash ?
			<div className='banner banner-image'>
				<div className='banner-text'>
					<h1>Cloud9 Client Store</h1>
					<Button variant="custom"className='banner-text'>Contact Us</Button>
				</div>
			</div>
		: 
			<div className='wrapper banner'>
				<section className='store'>
					<Container>
						<Row>
							<Col className='product-list' sm={0} md={0} lg={3} xl={3}>
								<Filter />
							</Col>
							<Col sm={12} md={12} lg={9} xl={9}>
								<Row>
									<Col className='product-list' sm={12} md={12} lg={12}>
										<Sort />
									</Col>
								</Row>
								<Row>
									<Col className='product-list' sm={12} md={12} lg={12}>
										<Product />
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
			}
			<Footer />
		</>
	)
}