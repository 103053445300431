export const setProducts = state => (
	{
		type: 'PRODUCTS',
		payload: state
	}
)

export const setSavedManufacturer = state => (
	{
		type: 'MAN2',
		payload: state
	}
)

export const setSavedCategory = state => (
	{
		type: 'CAT2',
		payload: state
	}
)

export const setSavedSubcategory = state => (
	{
		type: 'SUB2',
		payload: state
	}
)


export const setLoading = state => (
	{
		type: 'LOADING',
		payload: state
	}
)

export const setUser = state => (
	{
		type: 'USER',
		payload: state
	}
)

export const setLoggedInStatus = state => (
	{
		type: 'LOGGED_IN_STATUS',
		payload: state
	}
)

export const setPageControls = state => (
	{
		type: 'PAGE_CONTROLS',
		payload: state
	}
)

export const setPageInfo = state => (
	{
		type: 'PAGE_INFO',
		payload: state
	}
)

export const setSplash = state => (
	{
		type: 'SPLASH',
		payload: state
	}
)

export const setCart = state => (
	{
		type: 'CART',
		payload: state
	}
)

export const setProduct = state => (
	{
		type: 'PRODUCT',
		payload: state
	}
)

export const toggleLogin = state => (
	{
		type: 'TOGGLE_LOGIN',
		payload: state
	}
)

export const toggleCart = state => (
	{
		type: 'TOGGLE_CART',
		payload: state
	}
)

export const toggleOrders = state => (
	{
		type: 'TOGGLE_ORDERS',
		payload: state
	}
)

export const toggleProduct = state => (
	{
		type: 'TOGGLE_PRODUCT',
		payload: state
	}
)

export const toggleProfile = state => (
	{
		type: 'TOGGLE_PROFILE',
		payload: state
	}
)

export const setCartData = state => (
	{
		type: 'CARTDATA',
		payload: state
	}
)

export const setStoreData = state => (
	{
		type: 'STORE_DATA',
		payload: state
	}
)

export const setFilters = state => (
	{
		type: 'FILTERS',
		payload: state
	}
)