let defaultState = {
	products: [],
	savedManufacturer: {},
	savedCategory: {},
	savedSubcategory: {},
	loading: false,
	user: {},
	loggedInStatus: false,
	pageControls: {
		limit: 10,
		page: 1,
		keywords: null,
		sort: 'relevance',
		man: [],
		cat: [],
		sub: [],
	},
	pageInfo: {
		hasNextPage: false,
		hasPrevPage: false,
		limit: 10,
		nextPage: null,
		page: 0,
		prevPage: null,
		totalDocs: 0,
		totalPages: 0
	},
	filters: {
		manufacturers: {},
		groups: {},
		categories: {},
		subcategories: {}
	},
	splash: true,
	cartModal: false,
	productModal: false,
	ordersModal: false,
	profileModal: false,
	loginModal: false,
	product: {},
	cart: {},
	cartData: {},
	store: [],
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'PRODUCTS':
			return {
				...state,
				products: action.payload
			}
		case 'MAN2':
			return {
				...state,
				savedManufacturer: action.payload
			}
		case 'CAT2':
			return {
				...state,
				savedCategory: action.payload
			}
		case 'SUB2':
			return {
				...state,
				savedSubcategory: action.payload
			}
		case 'LOADING':
			return {
				...state,
				loading: action.payload
			}
		case 'USER':
			return {
				...state,
				user: action.payload
			}
		case 'LOGGED_IN_STATUS':
			return {
				...state,
				loggedInStatus: action.payload
			}
		case 'PAGE_CONTROLS':
			return {
				...state,
				pageControls: action.payload
			}
		case 'PAGE_INFO':
			return {
				...state,
				pageInfo: action.payload
			}
		case 'SPLASH':
			return {
				...state,
				splash: action.payload
			}
		case 'TOGGLE_CART':
			return {
				...state,
				cartModal: action.payload
			}
		case 'TOGGLE_PRODUCT':
			return {
				...state,
				productModal: action.payload
			}
		case 'TOGGLE_ORDERS':
			return {
				...state,
				ordersModal: action.payload
			}
		case 'TOGGLE_LOGIN':
			return {
				...state,
				loginModal: action.payload
			}
		case 'TOGGLE_PROFILE':
			return {
				...state,
				profileModal: action.payload
			}
		case 'PRODUCT':
			return {
				...state,
				product: action.payload
			}
		case 'CART':
			return {
				...state,
				cart: action.payload
			}
		case 'CARTDATA':
			return {
				...state,
				cartData: action.payload
			}
		case 'STORE_DATA':
			return {
				...state,
				store: action.payload
			}
		case 'FILTERS':
			return {
				...state,
				filters: action.payload
			}
		default: return state;
	}
}

export default reducers;