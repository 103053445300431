import React, { useState, useEffect } from 'react'
import { Tab, Tabs, Card, Col, Row, Container, Form, FormControl, 
	Spinner, Button, Table, ButtonGroup, Badge, Modal, DropdownButton,
	Dropdown, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios'

import Navigation from './../components/Navigation'
import Product from './../components/Product'
import Footer from './../components/Footer'

import { setPageControls } from '../actions/actions.js'


export default function AdminPage(props) {
	const dispatch = useDispatch()
	const pageControls = useSelector(state => state.reducers.pageControls)
	const loggedInUser = useSelector(state => state.reducers.user)
	const [ tab, setTab ] = useState('clients')
	const [ users, setUsers ] = useState([])
	const [ newUser, setNewUser ] = useState({})
	const [ clients, setClients ] = useState([])
	const [ sales, setSales ] = useState([])
	const [ sale, setSale ] = useState({details: { info: []}})
	const [ modal, setModal ] = useState(false)
	const [ confirmModal, setConfirmModal ] = useState(false)
	const [ user, setUser ] = useState({ client: ''})
	const [ client, setClient ] = useState({ users: [], locations: [], orders: []})
	const [ product, setProduct ] = useState({})
	const [ selected, setSelected ] = useState([])
	const [ role, setRole ] = useState()
	const [ input, setInput ] = useState('')
	const [ userSearch, setUserSearch ] = useState('')
	const [ roleFilter, setRoleFilter ] = useState('')
	const [ blockedToggle, setBlockedToggle ] = useState(false)
	const [ update, setUpdate ] = useState(false)
	const [ clientFilter, setClientFilter ] = useState('active')
	const [ clientSearch, setClientSearch ] = useState('')
	const [ saleSearch, setSaleSearch ] = useState('')
	const [ keywords, setKeywords ] = useState('')
	const [ loading, setLoading ] = useState(false)

	useEffect(() => {
		getUsers()
	}, [])

	useEffect(() => {
		if (tab === 'users')
			getUsers()
		else if (tab === 'clients')
			getClients()
		else if (tab === 'orders')
			getSales()
	}, [tab, update])

	function getUsers() {
		axios.get('/users')
		.then(response => {
			setUsers(response.data)
		})
		.catch(err=> {
			console.log(err)
		})
	}

	function getClients() {
		axios.get('/clients')
		.then(response => {
			setClients(response.data)
		})
		.catch(err=> {
			console.log(err)
		})
	}

	function getSales() {
		axios.get('/sales')
		.then(response => {
			var sales = response.data
			setSales(sales)
			if (sales.length > 0){
				var arr = []
				sales.forEach(sale => {
					arr.push(sale.po)
				})
				axios.get('/orders', {
					params: { pos: arr}
				})
				.then(orders => {
					var keys = Object.keys(orders)

					sales.forEach(sale => {
						var obj = orders.data[sale.po]
						if (obj){
							sale.status = obj.status
							if (obj.in)
								sale.in = obj.in
							if (obj.on)
								sale.on = obj.on
							if (obj.total)
								sale.total = obj.total
						}
					})
					setSales([...sales])
				})
				.catch(err => {
					console.log(err)
				})
			}
		})
		.catch(err=> {
			console.log(err)
		})
	}

	async function getOrderDetails(sale) {
		var shipmentData = {}
		var invoiceData = {}

		if (sale.in) {
			var trackingNumbers = { track: []}

			await axios.get('/invoices', 
			{
				params: {
					IN: [sale.in]
				}
			})
			.then(invoice => {
				invoiceData = { details: invoice.data[sale.in] }
			})
			.catch(err => {
				console.error(err)
			})
			await axios.get('/shipment', 
			{
				params: {
					ON: sale.on,
					IN: sale.in
				}
			})
			.then(shipment => {
				shipmentData = { track: shipment.data }
			})
			.catch(err => {
				console.error(err)
			})
			setSale({...sale, ...invoiceData, ...shipmentData})
		}
	}

	function modifyUser(user, close) {
		axios.put('/user', {user: user})
		.then(response => {
			if (close)
				setModal(false)
			getUsers()
			toast.success('Changes have been saved')
			if (newUser.email === user.email) {
				client.users.push(response.data)
				setNewUser({...{}})
			}
		})
		.catch(err => {
			console.log(err)
			toast.error('An error has occurred! Changes have not been saved')
		})
	}

	function deleteUser(user) {
		axios.delete('/user', {
			params: {
				email: user.email
			}
		})
		.then(response => {
			setConfirmModal(false)
			toast.success(user.name + " has been successfully deleted")
			getUsers()
		})
		.catch(err=> {
			toast.error("There was an error. " + user.name + " has not been deleted")
			console.log(err)
		})
	}

	function createUser(user) {
		axios.post('/user', {user: user})
		.then(response => {
			setModal(false)
			getUsers()
			toast.success(user.name + ' has been created')
		})
		.catch(err => {
			console.log(err)
			toast.error('An error has occurred! ' + user.name + ' has not been created')
		})
	}

	function modifyClient(client) {
		axios.put('/client', { client: client})
		.then(response => {
			toast.success('Client info has been saved')
		})
		.catch(err => {
			toast.error('Error! Unable to save client info')
			console.error(err)
		})
	}

	function updateClients() {
		axios.put('/update-clients')
		.then(response => {
			setLoading(false)
		})
		.catch(err => {
			console.error(err)
		})
	}

	function updateProducts() {
		axios.put('/update-products')
		.then(response => {
			setLoading(false)
		})
		.catch(err => {
			console.error(err)
		})
	}

	let userList = users.filter(entry => {
		var flag = true
		if (roleFilter) {
			flag = false
			if (roleFilter === 'master_admins' && entry.master_admin)
				flag = true
			else if (roleFilter === 'admins' && entry.admin && !entry.master_admin)
				flag = true
			else if (roleFilter === 'users' && !entry.master_admin && !entry.admin)
				flag = true
			else if (roleFilter === 'blocked' && !entry.client)
				flag = true
		}
		if (entry.client) {
			return flag && (Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(userSearch.toLowerCase())) ||
				Object.values(entry.client).some(val => typeof val === "string" && val.toLowerCase().includes(userSearch.toLowerCase())))
		}
		else
			return flag && Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(userSearch.toLowerCase()))
	}).map((user, i) => {
		return (
			<tr key={user.email}>
				<td>
					<span>
						{user.client ? 
							(user.master_admin ?
								(
									<Badge className='user-badge mr-2' variant="dark">{user.client.name} + Site Admin</Badge>
								)
							:
								(user.admin ?
									<Badge className='user-badge' variant="danger">{user.client.name + ' Admin'}</Badge>
								:
									<Badge className='user-badge' variant="info">{user.client.name}</Badge> 
								)
							)
						:
							(user.master_admin ?
								(
									<Badge className='user-badge mr-2' variant="dark">Site Admin</Badge>
								)
							:
								(user.admin ?
									<Badge className='user-badge' variant="danger">Admin</Badge>
								:
									<Badge className='user-badge' variant="info">Unassigned</Badge> 
								)
							)
						}
					</span>
					<br></br>
					<span>{user.name}</span>
				</td>
				<td>{user.email}</td>
				<td className='logo-white-box'>{Object.keys(user.cart).length}</td>
				<td className='edit-users'>
					<ButtonGroup className=''>
						<Button className='m-1' variant='main' size='sm' onClick={(e) => {
							setUser(user)
							if (user.master_admin)
								setRole('master_admin')
							else if (user.admin)
								setRole('admin')
							else
								setRole('user')
							if (user.client)
								setSelected([user.client.name])
							else
								setSelected(['Unassigned'])
							setModal(true)
						}}>
							<div className="fas fa-pen product-button plus"></div>
						</Button>
						<Button className='m-1' variant={user.active ? 'main' : 'danger'} size='sm' onClick={() => {
							if (loggedInUser.email !== user.email) {
								user.active = !user.active
								modifyUser({...user, client: true}, false)
							}
						}}>
							<div className="fas fa-power-off product-button plus"></div>
						</Button>
						<Button className='m-1' variant='danger' size='sm' onClick={() => {
							setUser(user)
							setConfirmModal(true)
						}}>
							<div className="fas fa-trash product-button plus"></div>
						</Button>
					</ButtonGroup>
				</td>
			</tr>
		)
	})


	let clientList = clients.filter(entry => {
		var flag = true
		if (clientFilter ) {
			if (clientFilter === 'active' && entry.users.length === 0)
				flag = false
			else if (clientFilter === 'inactive' && entry.users.length !== 0)
				flag = false
		}
			return flag && Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(clientSearch.toLowerCase()))
	})
	.map((client, i) => {
		return (
			<tr key={i}>
				<td>{client.name}</td>
				<td className='logo-white-box'>{client.users.length}</td>
				<td className='logo-white-box'>{client.orders.length}</td>
				<td className='logo-white-box'>
					<ButtonGroup className=''>
						<Button className='m-1' variant='main' size='sm' onClick={(e) => {
							setClient(client)
							setModal(true)
						}}>
							<div className="fas fa-pen product-button plus"></div>
						</Button>
					</ButtonGroup>
				</td>
			</tr>
		)
	})

	let clientUserList = client.users.sort((a, b) => b.master_admin - a.master_admin || b.admin - a.admin).map((user, i) => {
		return (
			<Form.Row key={user.email} className='m-0 mt-3'>
				<Col lg={4} md={4} sm={9} xs={9}>
					{user.master_admin ? 
						<Badge className='admin-badge' variant="dark">{user.name}</Badge> 
					: 
						(user.admin ? 
							<Badge className='admin-badge' variant="danger">{user.name}</Badge>
						: 
							<div>{user.name}</div>
						)
					}
				</Col>
				<Col lg={6} md={6} sm={9} xs={9} className=''>
					{user.email}
				</Col>
				<Col lg={2} md={2} sm={3} xs={3} className='edit-users'>
					<ButtonGroup className=''>
						<Button className='mr-2' variant={user.admin || user.master_admin ? 'main' : 'danger'} size='sm' onClick={() => {
							if (loggedInUser.email !== user.email) {
								user.admin = !user.admin
								modifyUser({...user, client: true}, false)
							}
						}}>
							<div className="fas fa-user-shield product-button plus"></div>
						</Button>
						<Button className='' variant={user.active ? 'main' : 'danger'} size='sm' onClick={() => {
							if (loggedInUser.email !== user.email) {
								user.active = !user.active
								modifyUser({...user, client: true}, false)
							}
						}}>
							<div className="fas fa-power-off product-button plus"></div>
						</Button>
					</ButtonGroup>
				</Col>
			</Form.Row>
		)
	})

	let clientLocationList = client.locations.map((location, i) => {
		return(
			<Form.Row key={i} className='m-0 mt-3'>
				<Form.Group as={Col} lg={5} md={11} sm={11} xs={11}>
					<Form.Control 
						name='address'
						value={client.locations[i].address_1 || ''}
						onChange={(e) =>{
							var newLocations = [...client.locations]
							if (newLocations[i]) {
								newLocations[i].address_1 = e.target.value
								setClient({...client, locations: newLocations})
							}
						}}
					/>
				</Form.Group>
				<Form.Group as={Col} lg={3} md={6} sm={6} xs={6}>
					<Form.Control 
						name='city'
						value={location.city || ''}
						onChange={(e) =>{
							var newLocations = [...client.locations]
							if (newLocations[i]) {
								newLocations[i].city = e.target.value
								setClient({...client, locations: newLocations})
							}
						}}
					/>
				</Form.Group>
				<Form.Group as={Col} lg={3} md={5} sm={5} xs={5}>
					<Form.Control 
						name='postal_code'
						value={location.postal_code || ''}
						onChange={(e) =>{
							var newLocations = [...client.locations]
							if (newLocations[i]) {
								newLocations[i].postal_code = e.target.value
								setClient({...client, locations: newLocations})
							}
						}}
					/>
				</Form.Group>
				<Form.Group as={Col} lg={1} md={1} sm={1} xs={1} className='p-0 edit-users'>
					<ButtonGroup className=''>
						<Button className='' variant='danger' size='sm' onClick={() => {
							client.locations.splice(i, 1)
							setClient({...client})
						}}>
							<div className="fas fa-times product-button plus"></div>
						</Button>
					</ButtonGroup>
				</Form.Group>
			</Form.Row>
		)
	})

	let clientOrderList = client.orders.map((order, i) => {
		return(
			<tr key={order.po}>
				<td>
					{order.po}
				</td>
				<td>
					{order.eu}
				</td>
				<td>
					{order.name}
				</td>
			</tr>
		)
	})

	let invoiceDetails = (sale.details ? sale.details : { info: []}).info.map((item, i) => {
		return (
			<span>{item.description}</span>
		)
	})

	let shipmentDetails = (sale.track ? sale.track : { items: []}).items.map((item, i) => {
		let subItem = item.info.map((subItem, i) => {
			return (
				<div></div>
			)
		})
		return (
			<tr>
				<td><span>{item.id}</span></td>
			</tr>
		)
	})

	let saleList = sales.filter(sale => {
		return Object.values(sale).some(val => typeof val === "string" && val.toLowerCase().includes(saleSearch.toLowerCase())) || 
			Object.values(sale.client).some(val => typeof val === "string" && val.toLowerCase().includes(saleSearch.toLowerCase()))
	})
	.map((sale, i) => {
		return(
			<tr key={i}>
				<td>{sale.po}</td>
				<td>{sale.client.name}</td>
				<td>{(new Date(sale.createdAt)).toLocaleDateString() + ' ' + (new Date(sale.createdAt)).toLocaleTimeString()}</td>
				<td className='logo-white-box'>{sale.status ? sale.status : <Spinner variant='main' animation="border" size="sm" /> }</td>
				<td className='logo-white-box'>
					<ButtonGroup className=''>
						<Button className='m-1' disabled={sale.status ? false : true} variant='main' size='sm' onClick={(e) => {
							setSale(sale)
							getOrderDetails(sale)
							setModal(true)
						}}>
							<div className="fas fa-eye product-button plus"></div>
						</Button>
					</ButtonGroup>
				</td>
			</tr>
		)
	})

	return (
		<div className='wrapper'>
			<ToastContainer 
				position="top-center"
			/>
			<Navigation history={props.history} match={props.match} />
			<Container className='mt-1 mb-3'>
				<Row>
					<Col lg={9} md={12} sm={12} xs={12}>
						<Tab.Container id="left-tabs-example" defaultActiveKey="first">
							<Tabs id="controlled-tab-example" activeKey={tab} onSelect={k => setTab(k)}>
								<Tab eventKey="clients" title="Clients">
									<Card body>
										<ButtonGroup className='mt-4'>
											<DropdownButton variant='main' size='sm' as={ButtonGroup} title="Filter" id="bg-nested-dropdown">
												<Dropdown.Item onClick={() => setClientFilter('')}>All</Dropdown.Item>
												<Dropdown.Item onClick={() => setClientFilter('active')}>Active</Dropdown.Item>
												<Dropdown.Item onClick={() => setClientFilter('inactive')}>Inactive</Dropdown.Item>
											</DropdownButton>
										</ButtonGroup>
										<div className='bg-white mb-3 mt-3 ml-0 p-1 rounded rounded-pill shadow-sm search-bar-size float-right'>
								  			<div className='searchbar'>
												<FormControl 
													type="text" 
													autoComplete="off"
													onChange={(event) => {
														setClientSearch(event.target.value)
														event.preventDefault()
													}}
													placeholder="Search Clients" 
													className="border-0 rounded rounded-pill searchbar-inner" 
												/>
											</div>
										</div>
										<Table responsive hover>
											<thead>
												<tr>
													<th>Client</th>
													<th className='logo-white-box'>Users</th>
													<th className='logo-white-box'>Orders</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{clientList}
											</tbody>
										</Table>
									</Card>
								</Tab>
								<Tab eventKey="users" title="Users">
									<Card body>
										<ButtonGroup className='mt-4'>
											<Button className='mr-2' variant='main' size='sm' onClick={() => {
												setUser({client: null, email: null, name: null})
												setSelected([])
												setRole('')
												setModal(true)
											}}>New User +</Button>
											<DropdownButton variant='main' size='sm' as={ButtonGroup} title="Filter" id="bg-nested-dropdown">
												<Dropdown.Item onClick={() => setRoleFilter('')}>All Users</Dropdown.Item>
												<Dropdown.Item onClick={() => setRoleFilter('users')}>Users</Dropdown.Item>
												<Dropdown.Item onClick={() => setRoleFilter('admins')}>Admins</Dropdown.Item>
												<Dropdown.Item onClick={() => setRoleFilter('master_admins')}>Site Admins</Dropdown.Item>
												<Dropdown.Item onClick={() => setRoleFilter('blocked')}>Unassigned Users</Dropdown.Item>
											</DropdownButton>
										</ButtonGroup>
										<div className='bg-white mb-3 mt-3 ml-0 p-1 rounded rounded-pill shadow-sm search-bar-size float-right'>
								  			<div className='searchbar'>
												<FormControl 
													type="text" 
													autoComplete="off"
													onChange={(event) => {
														setUserSearch(event.target.value)
														event.preventDefault()
													}}
													placeholder="Search Users" 
													className="border-0 rounded rounded-pill searchbar-inner" 
												/>
											</div>
										</div>
										<Table responsive hover>
											<thead>
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th className='logo-white-box'>Cart</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{userList}
											</tbody>
										</Table>
									</Card>
								</Tab>
								<Tab eventKey="orders" title="Orders">
									<Card body>
										<div className='bg-white mb-3 mt-3 ml-0 p-1 rounded rounded-pill shadow-sm search-bar-size float-right'>
								  			<div className='searchbar'>
												<FormControl 
													type="text" 
													autoComplete="off"
													onChange={(event) => {
														setSaleSearch(event.target.value)
													}}
													placeholder="PO #, PayPal ID, Order # or Client" 
													className="border-0 rounded rounded-pill searchbar-inner" 
												/>
											</div>
										</div>
										<Table responsive hover>
											<thead>
												<tr>
													<th>PO #</th>
													<th>Client</th>
													<th>Created</th>
													<th className='logo-white-box'>Status</th>
												</tr>
											</thead>
											<tbody>
												{saleList}
											</tbody>
										</Table>
									</Card>
								</Tab>
							</Tabs>
						</Tab.Container>
					</Col>
					<Col lg={3} md={12} sm={12} xs={12} className='pl-lg-0'>
						<Card body className='mt-lg-5 mt-2'>
							<b>Update Actions</b><br></br>
							<Button block className='mt-2' disabled={loading} variant='main' size='sm' onClick={() => {
								setLoading(true)
								updateClients()
							}}>Clients
								<div className="ml-2 fas fa-download product-button plus"></div>
							</Button>
							<Button block className='mt-3' disabled={loading} variant='main' size='sm' onClick={() => {
								setLoading(true)
								updateProducts()
							}}>Products
								<div className="ml-2 fas fa-download product-button plus"></div>
							</Button>
							<div className='plus'>*Takes up to 30 minutes to complete</div>
						</Card>
					</Col>
				</Row>
			</Container>			
			<Footer />
			<Modal
				show={modal}
		        onHide={() => {
		        	setUpdate(!update)
		        	setModal(false)
		        }}
		        size={tab === 'clients' || 'orders' ? 'xl' : 'md'}
		        scrollable={tab === 'clients' ? true : false}
			>
				<ToastContainer 
					position="top-center"
				/>
				<Modal.Header closeButton>
					{ tab === 'users' ?
						(
							user._id ?
								<Modal.Title id="contained-modal-title-vcenter">
									{user.name + " <" + user.email + '>'}
								</Modal.Title>
							:
								<Modal.Title id="contained-modal-title-vcenter">
									Create User
								</Modal.Title>
						)
					:
						tab === 'clients' ?
							<Modal.Title id="contained-modal-title-vcenter">
								{client.name}
							</Modal.Title>

						:
							<Modal.Title id="contained-modal-title-vcenter">
								{sale.po}
							</Modal.Title>
					}
				</Modal.Header>
				<Modal.Body>
					{ tab === 'users' ?

						<Form>
							<Form.Row>
								<Form.Group as={Col} lg={6} md={12} sm={12}>
									<Form.Label>Name</Form.Label>
									{
										user._id ?
											<Form.Control 
												name='name'
												value={user.name || ''}
												disabled
											/>
										:
											<Form.Control 
												name='name'
												value={user.name || ''}
												required
												onChange={(e) => setUser({...user, name: e.target.value})}
											/>
									}
								</Form.Group>
								<Form.Group as={Col} lg={6} md={12} sm={12}>
									<Form.Label>Email</Form.Label>
									{
										user._id ?
											<Form.Control 
												type="email" 
												name='email'
												value={user.email || ''}
												disabled
											/>
										:
											<Form.Control 
												name='email'
												value={user.email || ''}
												onChange={(e) => setUser({...user, email: e.target.value})}
											/>
									}
								</Form.Group>								
							</Form.Row>
							<Form.Row>
								<Form.Group as={Col} lg={6} md={12} sm={12}>
									<Form.Label>Role</Form.Label>
									<Form.Control 
										as="select"  
										onChange={event => {
											setRole(event.target.value)
											if (event.target.value === 'master_admin')
												setUser({...user, admin: true, master_admin: true})
											else if (event.target.value === 'admin')
												setUser({...user, admin: true, master_admin: false})
											else
												setUser({...user, admin: false, master_admin: false})
										}}
										value={role || 'undefined'}
										name='role'
									>
										<option value='user'>User</option>
										<option value='admin'>Admin</option>
										<option value='master_admin'>Site Admin</option>
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} lg={6} md={12} sm={12}>
									<Form.Label>Client</Form.Label>
									<Typeahead
										inputProps={
											{
												autoComplete: 'nope',
												name: "client",
												value: input
											}
										}
										id="basic-typeahead-example"
										labelKey="name"
										onChange={clientArray => {
											if (clientArray.length > 0) {
												setSelected(clientArray)
												setUser({...user, client: {_id: clientArray[0]._id}})
											}
										}}
										options={clients}
										selected={selected}
										onInputChange={input => setSelected([input])}
									/>
								</Form.Group>
								<Form.Group as={Col} lg={12} md={12} sm={12}>
										{
											user._id ?
												<>
													<Form.Label>Actions</Form.Label>
													<div>
														<Button variant="main" className='pl-3 pr-3' onClick={() => {
															modifyUser({...user, cart: {}}, true)
														}}>
															Empty Cart
														</Button>
													</div>
												</>
											:
												''
										}
								</Form.Group>
							</Form.Row>
						</Form>

					:
						tab === 'clients' ?
							
							<Form>
								<Form.Row>
									<Form.Group as={Col} lg={5} md={12} sm={12}>
										<Form.Label>Name</Form.Label>
										<Form.Control 
											name='name'
											value={client.name || ''}
											disabled
										/>
									</Form.Group>
									<Form.Group as={Col} lg={3} md={12} sm={12}>
										<Form.Label>Phone</Form.Label>
										<Form.Control 
											type="text" 
											name='phone'
											value={client.phone || ''}
											onChange={(e) => {
												setClient({...client, phone: e.target.value})
											}}
										/>
									</Form.Group>
									<Form.Group as={Col} lg={4} md={12} sm={12}>
										<Form.Row>
											<Form.Group className='mb-1' as={Col} lg={12} md={12} sm={12}>
												<Form.Label>Preferences:</Form.Label>
											</Form.Group>
										</Form.Row>
										<Form.Row>
											<Form.Group className='mb-1' as={Col} lg={6} md={12} sm={12}>
												<Form.Label onClick={() => setClient({...client, shipComplete: !client.shipComplete})} className='client-check'>Ship Complete<Form.Check className='float-right ml-3' onChange={() => console.log()} checked={client.shipComplete ? true : false} /></Form.Label>
											</Form.Group>
											<Form.Group as={Col} lg={6} md={12} sm={12}>
												<Form.Label onClick={() => setClient({...client, 
													limitOrderAmount: 
													{ apply: client.limitOrderAmount.apply === 'Y' ? 'N' : 'Y', amount: client.limitOrderAmount.amount}})} className='client-check'>Order Limit<Form.Check className='float-right ml-3' onChange={() => console.log()} checked={client.limitOrderAmount && client.limitOrderAmount.apply === 'Y' ? true : false} /></Form.Label>
												{
													client.limitOrderAmount && client.limitOrderAmount.apply === 'Y' ?
														<Form.Control 
															type="number" 
															name='orderLimit'
															value={(client.limitOrderAmount ? client.limitOrderAmount.amount : '') || ''}
															onChange={(e) => {
																setClient({...client, limitOrderAmount: { ...client.limitOrderAmount, amount: e.target.value}})
															}}
														/>
													:
														''
												}
											</Form.Group>
										</Form.Row>
									</Form.Group>							
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} lg={6} md={12} sm={12} className='pr-lg-3'>
										<Form.Label>Locations</Form.Label>
										<Button className='float-right' variant='main' size='sm' onClick={() => {
											client.locations.push({})
											setClient({...client})
										}}>Add Location
											<div className="ml-2 fas fa-plus product-button plus"></div>
										</Button>
										{clientLocationList}
										{clientLocationList.length === 0 ? 
											<div>No saved locations...</div>
											:
											''
										}
									</Form.Group>
									<Form.Group as={Col} lg={6} md={12} sm={12} className='pl-lg-3'>
										<Form.Label>Users</Form.Label>
										<Table responsive hover>
											<tbody>
												{clientUserList}
											</tbody>
										</Table>
										<InputGroup className="mb-3" size='sm'>
											<Form.Control 
												type="text" 
												name='newUser'
												value={newUser.email}
												onChange={(e) => {
													setNewUser({...newUser, email: e.target.value, client: {_id: client._id}})
												}}
											/>
											<InputGroup.Append>
												<Button className='ml-1 float-right' variant='main' size='sm' onClick={() => {
													modifyUser(newUser, false)
												}}>
													Add User
													<div className="ml-2 fas fa-plus product-button plus"></div>
												</Button>
											</InputGroup.Append>
										</InputGroup>
										{clientUserList.length === 0 ? 
											<div>No assigned users...</div>
											:
											''
										}
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} lg={12} md={12} sm={12}>
										<Form.Label>Orders</Form.Label>
										{clientOrderList}
										{clientOrderList.length === 0 ? 
											<div>No orders found...</div>
											:
											''
										}
									</Form.Group>
								</Form.Row>
							</Form>

						:
							(sale.in ? 
									<Container>
										<Row className='mb-3'>
											<Col lg={6} md={12} sm={12} xs={12}>
												Client: {sale.client ? sale.client.name : null}<br></br>
												Created: {(new Date(sale.createdAt)).toLocaleDateString() + ' ' + (new Date(sale.createdAt)).toLocaleTimeString()}<br></br>
												Status: {sale.status}<br></br>
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												Cloud9 PO #: {sale.po} <br></br>
												{sale.details ? <span>Client PO #: {sale.details.eu}<br></br></span> : ''}
												PayPal #: {sale.paypal_id}<br></br>
											</Col>
										</Row>
										<Row className='mb-3'>
											<Col lg={4} md={12} sm={12} xs={12}>
												<h5>Ship To</h5>
												{ sale.details ? 
													<div>
														{sale.details.to ? <span>{sale.details.to}<br></br></span> : ''}
														{sale.details.address_1 ? <span>{sale.details.address_1}<br></br></span> : ''}
														{sale.details.address_2 ? <span>{sale.details.address_2}<br></br></span> : ''}
														{sale.details.address_3 ? <span>{sale.details.address_3}<br></br></span> : ''}
														{sale.details.city ? <span>{sale.details.city}, </span> : ''}
														{sale.details.province ? <span>{sale.details.province}, </span> : ''}
														{sale.details.postal_code ? <span>{sale.details.postal_code}<br></br></span> : ''}
													</div>
													:
														''
												}	
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<h4>Items</h4>
												<Table>
													<tbody>
														{invoiceDetails}
													</tbody>
												</Table>
											</Col>
										</Row>
										<Row>
											<Col lg={12} md={12} sm={12} xs={12}>
												<h4>Items</h4>
												<Table>
													<tbody>
														{shipmentDetails}
													</tbody>
												</Table>
											</Col>
										</Row>
									</Container>
								:
									<Container>
										<Row>
											<Col lg={6} md={12} sm={12} xs={12}>
												Client: {sale.client ? sale.client.name : null}<br></br>
												Created: {(new Date(sale.createdAt)).toLocaleDateString() + ' ' + (new Date(sale.createdAt)).toLocaleTimeString()}<br></br>
												Status: {sale.status}<br></br>
												Sub-Total: ${sale.total}
											</Col>
											<Col lg={6} md={12} sm={12} xs={12}>
												Cloud9 PO #: {sale.po} <br></br>
												PayPal #: {sale.paypal_id}<br></br>
											</Col>
										</Row>
									</Container>
							)
					}
					{console.log(sale)}
				</Modal.Body>
				<Modal.Footer>
					{ tab === 'users' ?
						<div>
							<Button variant="secondary" className='pl-3 pr-3' onClick={() => {
								setModal(false)
								setUser({})
							}}>
								Cancel
							</Button>
							{
								user._id ?
									<Button variant="info" className='pl-3 pr-3' onClick={() => {
										if (!user.client)
											modifyUser({...user, client: {_id: false}}, true)
										else
											modifyUser(user, true)
									}}>
										Save
									</Button>
								:
									<Button variant="info" className='pl-3 pr-3' onClick={() => {
										if (!user.client)
											createUser({...user, client: {_id: false}})
										else
											createUser(user)
									}}>
										Save
									</Button>
							}
						</div>

					:
						tab === 'clients' ?
							
							<div>
								<Button variant="secondary" className='pl-3 pr-3' onClick={() => {
									setModal(false)
									setClient({ users: [], locations: [], orders: []})
									setUpdate(!update)
								}}>
									Cancel
								</Button>
								<Button variant="info" className='pl-3 pr-3' onClick={() => {
									setModal(false)
									modifyClient(client)
									setClient({ users: [], locations: [], orders: []})
									setUpdate(!update)
								}}>
								Save
								</Button>
							</div>

						:
							<div>
								<Button variant="secondary" className='pl-3 pr-3' onClick={() => {
									setModal(false)
								}}>
									Close
								</Button>
							</div>
						
					}
				</Modal.Footer>
			</Modal>
			<Modal
				show={confirmModal}
		        onHide={() => setConfirmModal(false)}
			>
				<ToastContainer 
					position="top-center"
				/>
				<Modal.Header closeButton>
					{
						tab === 'users' ? 
							<Modal.Title id="contained-modal-title-vcenter">
								Delete User?
							</Modal.Title>
						:
							''
					}
				</Modal.Header>
				<Modal.Body>
					{
						tab === 'users' ? 
							user.name + ' <' + user.email + '> will be deleted. Press Ok to confirm.'
						:
							''
					}
				</Modal.Body>
				<Modal.Footer>
					{
						tab === 'users' ?
							<>
								<Button variant="secondary" className='pl-3 pr-3' onClick={() => {
									setUser({ client: ''})
									setConfirmModal(false)
								}}>
									Cancel
								</Button>
								<Button variant="info" className='pl-3 pr-3' onClick={() => {
									deleteUser(user)
								}}>
									Ok
								</Button>
							</>
						:
							''
					}
				</Modal.Footer>
			</Modal>
		</div>
	)
}