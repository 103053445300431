import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Form, Card, Collapse, Badge, Spinner} from 'react-bootstrap';
import axios from 'axios'

import { setSavedCategory, setSavedSubcategory, setSavedManufacturer, 
	setPageControls, setFilters } from '../actions/actions.js'

export default function Filter(props) {
	const dispatch = useDispatch()
	const [ loadingState, setLoadingState ] = useState(false)
	const [ manState, setManState ] = useState(false)
	const [ catState, setCatState ] = useState(false)
	const [ subState, setSubState ] = useState(false)
	const [ currentKeywords, setCurrentKeywords ] = useState('')

	const savedManufacturer = useSelector(state => state.reducers.savedManufacturer)
	const savedCategory = useSelector(state => state.reducers.savedCategory)
	const savedSubcategory = useSelector(state => state.reducers.savedSubcategory)
	const pageControls = useSelector(state => state.reducers.pageControls)
	const filters = useSelector(state => state.reducers.filters)

	useEffect(() => {
		if (pageControls.keywords) {
			if (currentKeywords !== pageControls.keywords) {
				setLoadingState(true)
				setCurrentKeywords(pageControls.keywords)
			}
			axios
			.get("/filters", 
				{
					params: {
						keywords: pageControls.keywords
					}
				}
			)
			.then(response => {
				setLoadingState(false)
				dispatch(setFilters(response.data))
			})
			.catch(error => {
				console.log("login error: ", error)
			})
		}
	}, [pageControls]) // eslint-disable-line react-hooks/exhaustive-deps

	function handleFilter(filter) {
		var newList = []
		switch(filter[0]) {
			case 'man' : 
				if (pageControls.man.indexOf(filter[1]) < 0) {
					dispatch(setPageControls({...pageControls, man: [...pageControls.man, filter[1]], page: 1}))
					dispatch(setSavedManufacturer({...savedManufacturer, [filter[1]]: filters.manufacturers[filter[1]]}))
				}
				else{
					newList = pageControls.man.filter(item => !filter[1].includes(item))
					dispatch(setPageControls({...pageControls, man: newList, page: 1}))
				}
				break;
			case 'cat' : 
				if (pageControls.cat.indexOf(filter[1]) < 0) {
					dispatch(setPageControls({...pageControls, cat: [...pageControls.cat, filter[1]], page: 1}))
					dispatch(setSavedCategory({...savedCategory, [filter[1]]: filters.categories[filter[1]]}))
				}
				else{
					newList = pageControls.cat.filter(item => !filter[1].includes(item))
					dispatch(setPageControls({...pageControls, cat: newList, page: 1}))
				}
				break;
			case 'sub' : 
				if (pageControls.sub.indexOf(filter[1]) < 0) {
					dispatch(setPageControls({...pageControls, sub: [...pageControls.sub, filter[1]], page: 1}))
					dispatch(setSavedSubcategory({...savedSubcategory, [filter[1]]: filters.subcategories[filter[1]]}))
				}
				else{
					newList = pageControls.sub.filter(item => !filter[1].includes(item))
					dispatch(setPageControls({...pageControls, sub: newList, page: 1}))
				}
				break;
			default :
				break;
		}
	}

	var manlist = Object.keys(filters.manufacturers).sort((a,b) => 
		filters.manufacturers[b].count - filters.manufacturers[a].count
	).map((key, i) => {
  		return (
  			<div key={key} onClick={() => handleFilter(['man', key])}>
      			<Form.Check className='float-left' onChange={() => console.log()} checked={pageControls.man.indexOf(key) > -1 ? true : false} />
      			<div className='pointer filter-desc'>
      			{ filters.manufacturers[key] ? filters.manufacturers[key].descr + '(' + filters.manufacturers[key].count + ')' : '' }
				</div>
			</div>
  		);
	})
	var catlist = Object.keys(filters.categories).sort((a,b) => 
		filters.categories[b].count - filters.categories[a].count
	).map((key, i) => {
  		return (
  			<div key={key} onClick={() => handleFilter(['cat', key])}>
      			<Form.Check className='float-left' onChange={() => console.log()} checked={pageControls.cat.indexOf(key) > -1 ? true : false} />
      			<div className='pointer filter-desc'>
					{ filters.categories[key] ? filters.categories[key].descr + '(' + filters.categories[key].count + ')' : '' }
				</div>
			</div>
  		);
	})
	var sublist = Object.keys(filters.subcategories).sort((a,b) =>
		filters.subcategories[b].count - filters.subcategories[a].count
	).map((key, i) => {
		return (
  		<div key={key} onClick={() => handleFilter(['sub', key])}>
      			<Form.Check className='float-left' onChange={() => console.log()} checked={pageControls.sub.indexOf(key) > -1 ? true : false} />
      			<div className='pointer filter-desc'>
					{ filters.subcategories[key] ? filters.subcategories[key].descr + '(' + filters.subcategories[key].count + ')' : '' }
				</div>
			</div>
  		);
	})

	return (
		<Card body className='filter'>
			<h6><Badge className='searchbar' variant="info">Vendors</Badge></h6>

			{ loadingState ? <div> Loading <Spinner animation="grow" variant='info' size='sm'/> </div> 
			: 	
				<div>
					{manlist.splice(0,10)}
					<div
						onClick={() => (manState === true ? setManState(false) : setManState(true))}
						aria-controls="manlist-collapse-text"
						aria-expanded={manState}
						className='filter-menu pointer'
					>
						{Object.keys(filters.manufacturers).length > 10 ? (manState ? 'Show less <<' : 'Show more >>') : (Object.keys(filters.manufacturers).length > 0 ? '' : 'Nothing to show') }
					</div>
					<Collapse in={manState}>
						<div id="manlist-collapse-text">
							{manlist}
						</div>
					</Collapse>
				</div>
			}

			<h6><Badge className='searchbar' variant="info">Categories</Badge></h6>
			
			{ loadingState ? <div> Loading <Spinner animation="grow" variant='info' size='sm'/> </div> 
			: 	
				<div>
					{catlist.splice(0,10)}
					<div
						onClick={() => (catState === true ? setCatState(false) : setCatState(true))}
						aria-controls="catlist-collapse-text"
						aria-expanded={catState}
						className='filter-menu pointer'
					>
						{ Object.keys(filters.categories).length > 10 ? (catState ? 'Show less <<' : 'Show more >>') : (Object.keys(filters.categories).length > 0 ? '' : 'Nothing to show') }
					</div>
					<Collapse in={catState}>
						<div id="catlist-collapse-text">
							{catlist}
						</div>
					</Collapse>
				</div>
			}



			<h6><Badge className='searchbar' variant="info">Subcategories</Badge></h6>
			
			{ loadingState ? <div> Loading <Spinner animation="grow" variant='info' size='sm'/> </div> 
			: 	
				<div>
					{sublist.splice(0,10)}
					<div
						onClick={() => (subState === true ? setSubState(false) : setSubState(true))}
						aria-controls="sublist-collapse-text"
						aria-expanded={subState}
						className='filter-menu pointer'
					>
						{ Object.keys(filters.subcategories).length > 10 ? (subState ? 'Show less <<' : 'Show more >>') : (Object.keys(filters.subcategories).length > 0 ? '' : 'Nothing to show') }
					</div>
					<Collapse in={subState}>
						<div id="sublist-collapse-text">
							{sublist}
						</div>
					</Collapse>
				</div>
			}
		</Card>
	)
}
