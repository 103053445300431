import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from 'axios'
import { Form, FormControl, Navbar, Nav, NavDropdown, Button, Spinner, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";

import Cart from './../components/CartModal'
import Profile from './../components/ProfileModal'
import Orders from './../components/MyOrdersModal'
import Login from './../components/LoginModal'

import { 
	setProducts, setLoggedInStatus, setUser, toggleOrders, toggleProfile, setProduct,
	toggleLogin, toggleCart, setCart, setPageInfo, toggleProduct,
	setCartData, setStoreData, setPageControls, setSplash } from '../actions/actions.js'

export default function Navigation(props) {
	const dispatch = useDispatch()
	const [ loading, setLoadingState ] = useState(false)
	const [ keywords, setKeywords ] = useState('')
	const [ itemsInCart, setItemsInCart ] = useState(0)

	const cart = useSelector(state => state.reducers.cart)
	const user = useSelector(state => state.reducers.user)
	const loggedInStatus = useSelector(state => state.reducers.loggedInStatus)
	const pageControls = useSelector(state => state.reducers.pageControls)
	const products = useSelector(state => state.reducers.product)

	useEffect(() => {
		if (pageControls.keywords) {
			setKeywords(pageControls.keywords)
			setLoadingState(true)
			axios
			.get("/products",
				{ 
					params: {
						keywords: pageControls.keywords,
						page: pageControls.page,
						limit: pageControls.limit,
						sort: pageControls.sort,
						man: pageControls.man,
						grp: pageControls.grp,
						cat: pageControls.cat,
						sub: pageControls.sub,
					}
				}
			)
			.then(response => {
				setLoadingState(false)
				if (pageControls.product == pageControls.keywords) {
					dispatch(setProduct(response.data.docs[0]))
					dispatch(toggleProduct(true))
				}
				dispatch(setProducts(response.data.docs))
				dispatch(setPageInfo({
					hasNextPage: response.data.hasNextPage,
					hasPrevPage: response.data.hasPrevPage,
					limit: response.data.limit,
					nextPage: response.data.nextPage,
					page: response.data.page,
					prevPage: response.data.prevPage,
					totalDocs: response.data.totalDocs,
					totalPages: response.data.totalPages
				}))
			})
			.catch(error => {
				console.log("login error: ", error)
			})
		}
	}, [pageControls]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!user.email) {
			axios.get('/user')
				.then(result => {
					dispatch(setCart(result.data.cart))
					dispatch(setUser({
						name: result.data.name,
						email: result.data.email,
						client: result.data.client,
						master_admin: result.data.master_admin})
					)
					dispatch(setLoggedInStatus(true))
					dispatch(toggleLogin(false))
				})
				.catch(err => {
					console.error(err)
				})

			axios.get('/store')
				.then(store => {
					dispatch(setStoreData(store.data))
				})
				.catch(err => {
					console.log(err)
				})
			}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (cart) {
			var count = 0
			Object.keys(cart).forEach( key => {
				count += parseInt(cart[key])
			})
			setItemsInCart(count)
		}
	}, [cart]) // eslint-disable-line react-hooks/exhaustive-deps

	function handleSubmit(event) {
		event.preventDefault()
		if (props.history)
			props.history.push('/')
		if (pageControls.product)
			delete pageControls.product

		dispatch(setPageControls({...pageControls, keywords: keywords, page: 1
		}))
	}

	return (
		<div>
			{Cart()}
			{Profile()}
			{Login()}
			{Orders()}
			<Navbar collapseOnSelect expand="lg" bg="light" variant="light" className='pb-4 container'>
				<Navbar.Brand as={Link} to='/' onClick={() => {
					dispatch(setPageControls({...pageControls, page: 1, man: [], cat: [], sub: [], keywords: ''}))
					dispatch(setSplash(true))
				}}>
					<img
						alt="Cloud9 Solutions Logo"
						src={'/images/c9-logo.svg'}
						height="50"
						className="d-inline-block align-top"
					/>
				</Navbar.Brand>
			  	<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			  	<Navbar.Collapse id="responsive-navbar-nav">
			  		<div className='container nav-collapsable'>
				  		<Form onSubmit={event => handleSubmit(event)} inline className='bg-white mr-auto ml-auto mt-3 p-1 rounded rounded-pill shadow-sm search-bar-size'>
				  			<div className='searchbar'>
								<FormControl value={keywords} type="text" autoComplete="off" name="keywords" onChange={event => setKeywords(event.target.value)} placeholder="Search Products" className="border-0 rounded rounded-pill searchbar-inner" />
								{ loading ? <Spinner animation="border" variant='main' size='sm' className='float-right spinner-search' /> : <Button variant="link" type='submit' className='float-right'><i className="fa fa-search"></i></Button>}
							</div>
						</Form>
				    	<Nav className="mt-3">
					      	{loggedInStatus ? 
					      		<>
						      		<Link to='' className='nav-link mt-2 mb-2' onClick={event => {event.preventDefault(); dispatch(toggleCart(true))}}>
							    		<div className='cart-view'>Shopping Cart</div>
							    		<div className="fas fa-shopping-cart">
							    			<Badge pill variant="main" className='cart-badge'>{itemsInCart}</Badge>
							    		</div>
						    		</Link>
						      		<NavDropdown alignRight title={user.name} id="collasible-nav-dropdown" className="mt-2 mb-2 ml-0">
						      			<Button className='dropdown-item' onClick={() => dispatch(toggleProfile(true))}>
						      				<div>
							      				<div className='profile-name'>
							      					{user.client.name}<br></br>
							      				</div>
						      					{user.email}<i className="pr-0 fas fa-user-circle"></i>
						      				</div>
						      			</Button>
						      			{user.master_admin ? 
						      				<Button as={Link} to='/admin' className='dropdown-item'>Admin Panel</Button>
						      				: null
						      			}
						      			<Button className='dropdown-item' onClick={() => dispatch(toggleOrders(true))}>Orders</Button>
							        	<NavDropdown.Divider />
							        	<Button className='dropdown-item' href='/logout'>Logout</Button>
							      	</NavDropdown>
						      	</>
					      		:
						      	<Button variant="main" className='pt-1 pb-1 pl-4 pr-4 mt-2 mb-2' onClick={event => {event.preventDefault(); dispatch(toggleLogin(true))}}>Login</Button>
							}
					    </Nav>
					</div>
			  	</Navbar.Collapse>
			</Navbar>
		</div>
	)
}
