import React, { useState, useEffect } from 'react'
import { Button, Modal, Table, Row, Col, Form, InputGroup, FormControl} from 'react-bootstrap';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import { PayPalButton } from "react-paypal-button-v2";
import { config } from './../config.js'
import validator from 'validator';

import { useDispatch, useSelector } from "react-redux";
import { toggleCart, setCart, toggleLogin, setCartData, setUser } from '../actions/actions.js'

export default function Cart(props) {
	const dispatch = useDispatch()

	const user = useSelector(state => state.reducers.user)
	const cart = useSelector(state => state.reducers.cart)
	const cartData = useSelector(state => state.reducers.cartData)
	const store = useSelector(state => state.reducers.store)
	const loggedInStatus = useSelector(state => state.reducers.loggedInStatus)
	const cartModal = useSelector(state => state.reducers.cartModal)

	const [ loggedIn, setLoggeIn ] = useState(false)
	const [ part, setPart ] = useState(null)
	const [ tab, setTab ] = useState('cart')
	const [ address, setAddress ] = useState('')
	const [ city, setCity ] = useState('')
	const [ postal_code, setPostal_Code ] = useState('')
	const [ provinceCode, setProvince ] = useState('BC')
	const [ locations, setLocations ] = useState([])
	const [ po, setPo ] = useState('')
	const [ selected, setSelected ] = useState([])
	const [ state, setState ] = useState({})
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ phone, setPhone ] = useState('')

	const translate = {
		CON: 'COST',
		MSR: 'RETAIL',
		MG: 'MANU_PART',
		VP: 'PART_NUM'
	}

	useEffect(() => {
		if (loggedInStatus && cart && Object.keys(cart).length > 0) {
			axios.get('/prices', 
				{ 
					params: {
						refId: Object.keys(cart), 
						refIdQual: 'VP'
					}
				})
				.then(response => {
					var IDs = Object.keys(response.data)
					var products = response.data
					var tempCart = cart
					var tempCartData = {}
					IDs.forEach(id => {
						tempCartData[id] = {}
						if (products[id].error){
							delete tempCart[id]
							toast.error(products[id].error + ". Please contact support@c9s.ca")
							axios
								.put("/user", { user: { ...user, tempCart } }
								)
								.then(response => {
								})
								.catch(error => {
									console.log(error)
								})
								
						}
						else {
							tempCartData[id].price = products[id].con
							tempCartData[id].msrp = products[id].msr
							tempCartData[id].required_eu = products[id].required_eu
							tempCartData[id].required_license = products[id].required_eu
							tempCartData[id].weight = products[id].weight
							tempCartData[id].description = products[id].description
							if (products[id].mg)
								tempCartData[id].mg = products[id].mg
							if (products[id].upc)
								tempCartData[id].upc = products[id].upc
						}						
					})
					dispatch(setCart(tempCart))
					dispatch(setCartData(tempCartData))					
			})
			.catch(err => {
				console.log(err)
			})

			var tempLocations = []
			setEmail(user.email)
			setName(user.name)
			setPhone(user.client.phone)
			var tempCart = cart
			var products = []

			user.client.locations.forEach(location => {
				if (location.address_1 !== null)
					if (location.address_2 !== null && location.address_2 !== 'null')
						tempLocations.push(location.address_1 + ", " + location.address_2)
					else
						tempLocations.push(location.address_1)
			})
			setLocations(tempLocations)
		}
		else if (loggedInStatus) {
			dispatch(setCartData({}))
			axios
				.put("/user", { user: { ...user, cart: {} } }
				)
				.then(response => {
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [cart , loggedInStatus]) // eslint-disable-line react-hooks/exhaustive-deps

	function handleSelect(option) {
		setSelected(option)
		if (option[0] && option[0].customOption) {
			if (city !== '' && postal_code !== '') {
				var location = {}


				location.address_1 = option[0].name
				setAddress(option[0].name)
				location.city = city
				location.postal_code = postal_code

				user.client.locations.push(location)
				setUser(user)

				axios.put('/client', { client: user.client })
					.then(response => {
						toast.success("You've succesfully added a new address for " + user.client.name)
						var tempLocations = user.locations
						tempLocations.push(option[0].name)
						setLocations(tempLocations)
					})
					.catch(err => {

					})
			}
			else
				toast.error('Please enter the city and postal code.')
		}
		else if (option[0] !== undefined) {
			setAddress(option[0])
			user.client.locations.forEach(location => {
				if (option[0] === location.address_1){
					setCity(location.city)
					setPostal_Code(location.postal_code)
				}					
			})
		}
	}

	function handleChange(input, product) {
		var value = input.target.value
		setState(prevState => ({...prevState, [product]: value}))
	}

	function editQty(toggle, key) {
		console.log(key)

		if (toggle) {
			setPart(key)
		}
		else if (!toggle) {
	    	if (Number.isInteger(parseInt(state[key])) && 
				parseInt(state[key]) >= 0) {
	    		setPart(null)
	    		var tempCart = cart
				tempCart[key] = parseInt(state[key])
				
		    	if (parseInt(state[key]) === 0){
		    		delete tempCart[key]
		   //  		dispatch(setCartData(cartData.filter(item => { 
					// 	return item.PART_NUM !== tempCartData[id].price = products[id].con;
					// })));
		    	}
		    	if (loggedInStatus)
		    		axios
						.put("/user", {user: {...user, cart: tempCart}})
						.then(response => {
							dispatch(setCart({...tempCart}))
						})
						.catch(error => {
							console.log("login error: ", error)
						})
				else {
					dispatch(setCart(tempCart))
				}
	    	}
		}
	}


	var disabledCheckout = true
	var disabledPayment = true
	var feeTotal = 0
	var subTotal = 0
	var list = Object.keys(cartData).map( (key, i) => {
		return(
			<tr key={i}>
				<td>
					<div href='#' className='product pointer'>{cartData[key].description}</div>
					<div className='plus'>
					</div>
				</td>
				<td>
					{ part === key ?
						<InputGroup size='sm'>
							<FormControl
								placeholder={''}
								className='search-query qty-bar'
								name={key}
								onChange={input => handleChange(input, key)}
								type="number" min="0" step="1"
							/>
							<InputGroup.Append>
							<Button variant="info" type='submit' onClick={() => editQty(false, key)}>
								<div className="fas fa-save product-button plus"></div>
							</Button>
							</InputGroup.Append>
						</InputGroup>
					: 
						<InputGroup size='sm'>
							<FormControl
								className='search-query qty-bar'
								name={key}
								defaultValue={cart[key]}
								disabled
							/>
							<InputGroup.Append>
							<Button variant="info" type='submit' onClick={() => editQty(true, key)}>
								<div className="fas fa-pen product-button plus"></div>
							</Button>
							</InputGroup.Append>
						</InputGroup>
					}

				</td>
				<td>
					<div className='justify-content-end'>
						{loggedInStatus ? 
							<div className='product-cost'>
									${((parseFloat(cartData[key].price)*cart[key]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
								</div>
								: 
								<div>
									<div className='product-cost pointer'>
  									{"$" + ((parseFloat(cartData[key].msrp)*cart[key]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
  								</div>
								</div>
							}
					</div>  
				</td>
			</tr>
		)
	})
	Object.keys(cartData).forEach(key => {
		if (loggedInStatus)
			subTotal += cart[key]*cartData[key].price
		else
			subTotal += cart[key]*cartData[key].msrp
	})
	var fees = store.map((key, i) => {
		feeTotal += (key.percent*subTotal)
		return(
			<tr key={key.desc} className='description'>
				<td>
					<div className='float-left'>{key.desc}</div>
				</td>
				<td>
					<div className='float-right'>${(key.percent*subTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
				</td>
			</tr>
		)})
	if (tab === 'cart') {
		if (Object.keys(cartData).length > 0)
			disabledCheckout = false
		else
			disabledCheckout = true
	}
	if (tab === 'info') {
		if (address !== '' && user.name !== '' && validator.isEmail(user.email) &&
			city !== '' && validator.isPostalCode(postal_code, ['CA']) &&
			po !== '' && cartData.length > 0 &&
			['AB','BC','NL','PE','NS','NB','QC','ON','MB','SK','YT','NT','NU'].includes(provinceCode)) {
			disabledPayment = false
		}
		else
			disabledPayment = true
	}
	return (
		<Modal
			size='xl'
			show={cartModal}
	        onHide={() => dispatch(toggleCart(false))}
		>
			<ToastContainer 
				position="top-center"
			/>
			<Modal.Header closeButton>
				{ tab === 'cart' ?

					<Modal.Title id="contained-modal-title-vcenter">
						Shopping Cart
					</Modal.Title>

				:
					tab === 'info' ?
						
						<Modal.Title id="contained-modal-title-vcenter">
							Shipping Info
						</Modal.Title>

					:
						<Modal.Title id="contained-modal-title-vcenter">
							Payment
						</Modal.Title>
					
				}
			</Modal.Header>
			<Modal.Body>
				<Row>
  					<Col lg={9} md={12} sm={12} className='float-left'>
						{	tab === 'cart' ?

							<Table responsive>
								<thead>
									<tr>
										<th>Description</th>
										<th>Quantity</th>
										<th><div className='float-right'>Price</div></th>
									</tr>
								</thead>
								<tbody>
									{list}
								</tbody>
							</Table>

						:
							tab === 'info' ? 

								<Form>
									<Form.Row>
										<Form.Group as={Col} lg={4} md={12} sm={12}>
											<Form.Label>ATTN</Form.Label>
											<Form.Control 
												type="text" 
												name='name'
												value={name}
												onChange={event => setName(event.target.value)}
											/>
										</Form.Group>
										<Form.Group as={Col} lg={4} md={12} sm={12}>
											<Form.Label>Email address</Form.Label>
											<Form.Control 
												type="email"
												name="email"
												value={email}
												onChange={event => setEmail(event.target.value)}
												autoComplete='nope'
											/>
										</Form.Group>
										<Form.Group as={Col} lg={4} md={12} sm={12}>
											<Form.Label>Phone</Form.Label>
											<Form.Control 
												type="phone"
												name="phone"
												value={phone}
												onChange={event => setPhone(event.target.value)}
												autoComplete='nope'
											/>
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Form.Group as={Col} lg={6} md={12} sm={12}>
											<Form.Label>Ship To</Form.Label>
											<Typeahead
												inputProps={
													{
														autoComplete: 'nope',
														name: "address1",
														value: address
													}
												}
												allowNew
												id="basic-typeahead-example"
												labelKey="name"
												newSelectionPrefix="Add new address: "
												onChange={handleSelect}
												options={locations}
												selected={selected}
												onInputChange={(input) => setAddress(input)}
											/>
										</Form.Group>
										<Form.Group as={Col} lg={6} md={12} sm={12}>
											<Form.Label>PO#</Form.Label>
											<Form.Control 
												autoComplete="nope"
												type="text" 
												name="po"
												value={po}
												onChange={event => setPo(event.target.value)}
											/>
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Form.Group as={Col} lg={4} md={12} sm={12}>
											<Form.Label>City</Form.Label>
											<Form.Control 
												onChange={event => setCity(event.target.value)}
												name='city'
												autoComplete='nope'
												value={city}
											/>
										</Form.Group>
										<Form.Group as={Col} lg={4} md={12} sm={12}>
											<Form.Label>Province</Form.Label>
											<Form.Control 
												as="select"  
												onChange={event => setProvince(event.target.value)}
												value={provinceCode}
												name='provinceCode'
											>
												<option value='AB'>AB</option>
												<option value='BC'>BC</option>
												<option value='MB'>MV</option>
												<option value='NB'>NB</option>
												<option value='NL'>NL</option>
												<option value='NS'>NS</option>
												<option value='ON'>ON</option>
												<option value='PE'>PE</option>
												<option value='QC'>QC</option>
												<option value='SK'>SK</option>
												<option value='NU'>NU</option>
												<option value='YT'>YT</option>
												<option value='SK'>SK</option>
											</Form.Control>
										</Form.Group>

										<Form.Group as={Col} lg={4} md={12} sm={12}>
											<Form.Label>Postcode</Form.Label>
											<Form.Control
												autoComplete='nope'
												onChange={event => setPostal_Code(event.target.value)}
												name='postal_code'
												value={postal_code}
											/>
										</Form.Group>
									</Form.Row>
								</Form>
							: 
								<PayPalButton
									amount={(subTotal + feeTotal).toFixed(2).toString()}
									// shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
									onSuccess={(details, data) => {
										// OPTIONAL: Call your server to save the transaction
										axios
											.post('/order',
											{
												id: data.orderID,
												order: {
													po: po,
													receivingContact: name,
													receivingAddress: address,
													city: city,
													postalCode: postal_code,
													name: user.name,
													phone: phone,
													email: email,
													provinceCode: provinceCode
												},
												products: cart

											}
										)
										.then(response => {
											toast.success('Your payment of $' + (subTotal + feeTotal).toFixed(2).toString() + " has been received. Your order has been submitted")
											axios
												.put("/user", { user: { ...user, cart: {} } })
												.then(response => {
													dispatch(setCartData([]))
													dispatch(setCart({}))
													setTab('cart')
													localStorage.setItem('userCart', {})
												})
												.catch(error => {
													console.log("login error: ", error)
												})
										})
										.catch(error => {
											console.log("payment error: ", error)
										})
									}}
									options={{clientId: config.clientId, currency: 'CAD'}}
								/>
						}
					</Col>
  					<Col lg={3} md={12} sm={12}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2"><div className='float-right'>Taxes & Fees</div></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<div className='float-left'>Subtotal:</div>
									</td>
									<td>
										<div className='float-right'>${subTotal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
									</td>
								</tr>
								{fees}
							</tbody>
						</Table>
  						<Table responsive>
  							<thead>
  								<tr>
  									<th><div className='float-left'>Total:</div></th>
  									<th><div className='float-right'>${(subTotal + feeTotal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div></th>
  								</tr>
  							</thead>
  						</Table>
  					</Col>
  				</Row>
			</Modal.Body>
			<Modal.Footer>
				{ tab === 'cart' ?
					<>
						<Button variant="secondary" className='pl-3 pr-3' onClick={() => dispatch(toggleCart(false))}>
							Continue Shopping
						</Button>
						{ loggedInStatus ? 
							<Button variant="info" disabled={disabledCheckout} className='pl-3 pr-3' onClick={() => setTab('info')}>
								Checkout
							</Button>
						:
							<Button variant="info" disabled={disabledCheckout} className='pl-3 pr-3' onClick={() => {dispatch(toggleCart(false)); dispatch(toggleLogin(true))}}>
								Login
							</Button>
						}
					</>

				:
					tab === 'info' ?
						<>
							<Button variant="secondary" className='pl-3 pr-3' onClick={() => setTab('cart')}>
								Shopping Cart
							</Button>
							<Button variant="info" disabled={false} className='pl-3 pr-3' onClick={() => setTab('pay')}>
								Payment
							</Button>
						</>

					:
						<>	
							<div>user: sb-ecwdt1644671@personal.example.com</div>
							<div>pass: AtQs9Q_Y</div>
							<Button variant="secondary" className='pl-3 pr-3' onClick={() => setTab('info')}>
								Back to Shipping Info
							</Button>
						</>
					
				}
			</Modal.Footer>
		</Modal>
	);
}
