import React, { useState } from 'react'
import { Button, Modal, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import popupTools from  'popup-tools';
import { setUser, setCartData, setLoggedInStatus, toggleLogin, setCart } from '../actions/actions.js'

export default function Login(props) {
	const dispatch = useDispatch()
	const [ access_denied, setAccess_Denied ] = useState(false)
	const loginModal = useSelector(state => state.reducers.loginModal)
	return (
		<Modal
			{...props}
			show={loginModal}
	        onHide={() => dispatch(toggleLogin(false))}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Login
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{access_denied ? 
					<Alert variant='danger'>
						Access denied: Please contact <Alert.Link href="mailto:support@c9s.ca">support@c9s.ca</Alert.Link>
					</Alert>
				:
					null
				}
    			<img
    				className="pointer"
					alt="Sign in with Microsoft"
					src={'/images/ms-light.svg'}
					onClick={() => popup()}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="info" className='pl-3 pr-3' onClick={() => dispatch(toggleLogin(false))}>Cancel</Button>
			</Modal.Footer>
		</Modal>
	);

	function popup() {
		popupTools.popup('/auth', 'Login Popup', { width: 400, height: 600 }, function(err, data) {

		    if (data && data.email) {
				dispatch(setCart(data.cart))
				dispatch(setUser({
					name: data.name,
					email: data.email,
					client: data.client,
					master_admin: data.master_admin,
					location: data.location,
					shipComplete: data.shipComplete,
					phone: data.phone,
					limit: data.limit})
				)
				dispatch(setLoggedInStatus(true))
				dispatch(toggleLogin(false))

				if (JSON.parse(localStorage.getItem('userCart')) !== null)
					dispatch(setCartData(JSON.parse(localStorage.getItem('userCart'))))
		    }
		    else {
		    	setAccess_Denied(true)
		    	dispatch(setUser({}))
				dispatch(setLoggedInStatus(false))
		    	setTimeout(() => {
		    		window.location = '/logout'
		    	}, 3000);
		    }
		});
	}
}

