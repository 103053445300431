import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Form, Card, Pagination, Row, Col, Button} from 'react-bootstrap';

import { setPageControls } from '../actions/actions.js'


export default function Sort(props) {
	const dispatch = useDispatch() 
	const savedManufacturer = useSelector(state => state.reducers.savedManufacturer)
	const savedCategory = useSelector(state => state.reducers.savedCategory)
	const savedSubcategory = useSelector(state => state.reducers.savedSubcategory)
	const filters = useSelector(state => state.reducers.filters)
	const pageControls = useSelector(state => state.reducers.pageControls)
	const pageInfo = useSelector(state => state.reducers.pageInfo)

	function handleFilter(filter) {
		switch(filter[0]) {
			case 'man' : 
				dispatch(setPageControls({...pageControls, page: 1, man: pageControls.man.filter(item => !filter[1].includes(item))}))
				break;
			case 'cat' : 
				dispatch(setPageControls({...pageControls, page: 1, cat: pageControls.cat.filter(item => !filter[1].includes(item))}))
				break;
			case 'sub' : 
				dispatch(setPageControls({...pageControls, page: 1, sub: pageControls.sub.filter(item => !filter[1].includes(item))}))
				break;
			default :
				break;
		}
	}

	let manList = pageControls.man.map((key, i) => {
		return(
			<Button key={key} className='tags' variant="info" onClick={() => handleFilter(['man', key])}>
				{filters.manufacturers[key] ? filters.manufacturers[key].descr + " x" 
				: 
				(savedManufacturer[key] ? savedManufacturer[key].descr + ' x' : key + ' x')}
			</Button>
		)
	})

	let catList = pageControls.cat.map((key, i) => {
		return(
			<Button key={key} className='tags' variant="info" onClick={() => handleFilter(['cat', key])}>
				{filters.categories[key] ? filters.categories[key].descr + " x" 
				: 
				(savedCategory[key] ? savedCategory[key].descr + ' x' : key + ' x')}
			</Button>
		)
	})

	let subList = pageControls.sub.map((key, i) => {
		return(
			<Button key={key} className='tags' variant="info" onClick={() => handleFilter(['sub', key])}>
				{filters.subcategories[key] ? filters.subcategories[key].descr + " x" 
				: 
				(savedSubcategory[key] ? savedSubcategory[key].descr + ' x' : key + ' x')
			}
			</Button>
		)
	})

	return (
		<Card body>
			<Row>
				<Col sm={12} md={3} lg={3}>
					<Form.Group controlId="form.perPage">
						<Form.Label>Items per page</Form.Label>
						<Form.Control name='perPage' onChange={event => dispatch(setPageControls({...pageControls, limit: event.target.value, page: 1}))} as="select" size='sm'>
							<option>10</option>
							<option>25</option>
							<option>50</option>
							<option>100</option>
						</Form.Control>
					</Form.Group>						
				</Col>
				<Col sm={12} md={3} lg={3}>
					<Form.Group controlId="form.sort">
						<Form.Label>Sort</Form.Label>
						<Form.Control as="select" size='sm' name='sort' onChange={event => dispatch(setPageControls({...pageControls, sort: event.target.value, page: 1}))}>
							<option value='relevance'>Relevance</option>
							<option value='price_low'>Price - Low to High</option>
							<option value='price_high'>Price - High to Low</option>
							<option value='manufacturer'>Manufacturer Part</option>
							<option value='part_number'>Tech Data Part</option>
						</Form.Control>
					</Form.Group>
				</Col>
				<Col sm={12} md={6} lg={6} className='text-right'>
					<Pagination size='sm' className='justify-content-end'>
						<Pagination.Prev onClick={() => {
							pageInfo.hasPrevPage ? dispatch(setPageControls({...pageControls, page: pageInfo.prevPage})) : console.log()
						}}/>
						<Pagination.Next onClick={() => {
							pageInfo.hasNextPage ? dispatch(setPageControls({...pageControls, page: pageInfo.nextPage})) : console.log()
						}}/>
					</Pagination>
					<div className='description'>
						{pageInfo.page} of&nbsp;
						{pageInfo.totalPages}
						&nbsp;({pageInfo.totalDocs} products)
					</div>
				</Col>
			</Row>
			{manList}
			{catList}
			{subList}
		</Card>
	)
}