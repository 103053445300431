import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';

export default class Footer extends Component {
	render() {
		return (
			<div>
				<section className="footer">
					<Container>
						<Row>
							<Col sm={12} md={12} lg={5}>
								<div>
									<h6>What we do</h6>
									<div className='green-line'></div>
									<div className='column-box'>
										<ul className="foot-menu columns-2">
											<li><a href="https://c9s.ca/managed-it-services">Managed IT Services</a></li>
											<li><a href="https://c9s.ca/it-consulting">IT Consulting</a></li>
											<li><a href="https://c9s.ca/office-365-consulting">Office 365 Consulting</a></li>
											<li><a href="https://c9s.ca/backup-disaster-recovery">Backup &amp; Disaster Recovery</a></li>
											<li><a href="https://c9s.ca/cloud-migration-hosting">Cloud Migration &amp; Hosting</a></li>
											<li><a href="https://c9s.ca/it-security-consulting">IT Security Consulting</a></li>
											<li><a href="https://c9s.ca/structured-cabling">Structured Cabling</a></li>
											<li><a href="https://c9s.ca/erp-consulting">ERP Consulting</a></li>
											<li><a href="https://c9s.ca/unified-communications">Unified Communications</a></li>
											<li><a href="https://c9s.ca/voip-hosted-phone">VoIP &amp; Hosted Phone</a></li>
											<li><a href="https://c9s.ca/wifi-ptp-networking">Wi-Fi / Point-to-Point</a></li>
											<li><a href="https://c9s.ca/managed-network">Managed Network</a></li>
										</ul>
	      							</div>
								</div>
							</Col>
							<Col sm={12} md={12} lg={4}>
								<Row>
									<Col sm={12} md={6} lg={6}>
										<div className='column-box'>
											<h6>Company</h6>
											<div className='green-line'></div>
											<ul className="foot-menu">
												<li><a href="https://c9solutions.screenconnect.com/">Remote Support</a></li>
												<li><a href="https://www.autotask.net/clientportal/">Client Portal Login</a></li>
												<li><a href="https://c9s.ca/why-us">Why Us?</a></li>
												<li><a href="https://c9s.ca/customer-testimonials">Testimonials</a></li>
												<li><a href="https://c9s.ca/our-team">Our Team</a></li>
												<li><a href="https://c9s.ca/blog">Blog</a></li>
											</ul>
										</div>
									</Col>
									<Col sm={12} md={6} lg={6}>
										<div className='column-box'>
											<h6>Locations</h6>
											<div className='green-line'></div>
											<ul className="foot-menu">
												<li><a href="https://c9s.ca/locations">Surrey Head Office</a></li>
												<li><a href="https://c9s.ca/locations">Vancouver Office</a></li>
												<li><a href="https://c9s.ca/locations">Abbotsford Office</a></li>
												<li><a href="https://c9s.ca/contact">Contact Us</a></li>
											</ul>
										</div>
									</Col>
								</Row>
							</Col>
							<Col sm={12} md={12} lg={3} className='logo-white-box'>
									<img alt='c9-logo-white' className='logo-white' src={'/images/c9-logo-white.svg'}></img>
									<div className="footer-info">
										<p>Cloud9 Solutions specialize in the SME market and provide the assurance, availability and agility that our Customers value. Our Canadian based dedicated team of IT Support professionals are friendly and hands-on, available 24/7 to assist.</p>
										<p><span><a href="mailto:+1 (604) 957-1555">+1 (604) 957-1555</a></span><span><a href="mailto:info@c9s.ca">info@c9s.ca</a></span></p>
										<p>#232, 18525 53 Ave, Surrey, BC, V3S 7A4</p>
										<ul className="social-icons">
											<li><a href="https://www.facebook.com/c9sdotca" target="_blank" rel="noopener noreferrer" title="Like us on Facebook" alt="Like us on Facebook"><i className="fab fa-facebook"></i></a></li>
											<li><a href="https://m.me/c9sdotca" target="_blank" rel="noopener noreferrer" title="Message us on Facebook" alt="Message us on Facebook"><i className="fab fa-facebook-messenger"></i></a></li>
											<li><a href="https://twitter.com/c9sdotca" target="_blank" rel="noopener noreferrer" title="Follow us on Twitter" alt="Follow us on Twitter"><i className="fab fa-twitter-square"></i></a></li>
											<li><a href="https://ca.linkedin.com/company/c9sdotca" target="_blank" rel="noopener noreferrer" title="Follow us on LinkedIn" alt="Follow us on LinkedIn"><i className="fab fa-linkedin"></i></a></li>
										</ul>
								</div>
							</Col>
						</Row>
					</Container>
				</section>	
				<section className='footer-end'>
					<Row className='footer-end'>
						<Col sm={12} md={12} lg={6}>
							<p>© 2020 Cloud9 Solutions, All Rights Reserved.</p>
						</Col>
						<Col sm={12} md={12} lg={6}>
							<p className="right-util">
								<a href="https://c9s.ca">Main Site</a>&nbsp;&nbsp;|&nbsp;&nbsp; 
								<a href="https://c9s.ca/website/privacy-policy">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp; 
								<a href="https://c9s.ca/contact">Contact Us</a></p>
						</Col>	
					</Row>
				</section>
			</div>
		)
	}
}