import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { toggleOrders } from '../actions/actions.js'

export default function OrdersModal(props) {
	const dispatch = useDispatch()
	const ordersModal = useSelector(state => state.reducers.ordersModal)

	return (
		<Modal
			{...props}
			show={ordersModal}
	        onHide={() => dispatch(toggleOrders(false))}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					My Orders
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" className='pl-3 pr-3' onClick={() => dispatch(toggleOrders(false))}>Cancel</Button>
				<Button variant="info" type='submit' className='pl-3 pr-3'>
					Login
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
